import React, { StrictMode } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { HistoryHelper } from "./helpers/historyHelper";
import { UserList } from "./pages/user/userListPage";
import { UserAdd } from "./pages/user/userAddPage";
import { UserEdit } from "./pages/user/userEditPage";
import { StorageFileList } from "./pages/storage/storageFileListPage";
import { Login } from "./pages/login/loginPage";
import { PrivateComponent } from "./components/layout/_index";

const App = () => {
    return (
        <StrictMode>
            <Router history={ HistoryHelper }>
                <Switch>
                    <PrivateComponent exact path="/users" component={ UserList } />
                    <PrivateComponent exact path="/users/add-user" component={ UserAdd } />
                    <PrivateComponent exact path="/users/edit-user/:id" component={ UserEdit } />
                    <PrivateComponent exact path="/storage" component={ StorageFileList } />
                    <Route exact path="/login" component={ Login } />
                    <Redirect from="*" to="/users" />
                </Switch>
            </Router>
        </StrictMode>
    );
};

export default App;
