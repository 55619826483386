import React from "react";
import * as Yup from "yup";
import config from "../../_config/config";
import { Nav, HeaderTitle } from "../../components/layout/_index";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "../../components/form/_index";
import { AlertError } from "../../components/alert/_index";
import { fetchHelper } from "../../helpers/fetchHelper";

const UserAdd = ({ history }) => {
    const { t } = useTranslation();
    const fetchHelperInstance = fetchHelper();
    const baseUrl = process.env.REACT_APP_API_URL;
    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t("email_error_message")).required(t("email_error_message")),
        password: Yup.string().required(t("password_error_message")),
        firstname: Yup.string().required(t("firstname_error_message")),
        lastname: Yup.string().required(t("lastname_error_message")),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema) 
    };
    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = ({ email, password, firstname, lastname }) => {
        const payload = {
            "email": email,
            "password": password,
            "firstName": firstname,
            "lastName": lastname,
            "permissionLevel": config.OPERATOR_PERMISSION_LVL,
            "status": config.USER_STATUS_PUBLIC
        };

        fetchHelperInstance.post(`${baseUrl}/users`, payload)
            .then((res) => {
                if (res.id) {
                    history.push("/users");   
                }
            })
            .catch(error => {
                setError("apiError", { message: error });
            });
    };

    return (
        <div className="flex bg-white min-h-screen">
            <Nav />
            <main className="flex-grow p-10 pl-64 space-y-6 text-gray-900">
                <div className="flex flex-col space-y-6">
                    <HeaderTitle title="user_add_page_title" />
                    <form onSubmit={ handleSubmit(onSubmit) }>
                        <div className="grid grid-cols-2 gap-10">
                            <div className="form-group">
                                <Input elementID="oprFirstName" label={ t("First name") } name="firstname" type="text" placeholder="es. Mario" errors={ errors } register={ register } />
                                <div className="invalid-feedback">{ errors.firstname?.message }</div>   
                            </div>
                            <div className="form-group">
                                <Input elementID="oprLastName" label={ t("Last name") } name="lastname" type="text" placeholder="es. Rossi" errors={ errors } register={ register } />
                                <div className="invalid-feedback">{ errors.lastname?.message }</div>
                            </div>
                            <div className="form-group">
                                <Input elementID="oprEmail" label="Email" name="email" type="email" placeholder="email@gmail.com" disableAutocomplete="true" errors={ errors } register={ register } />
                                <div className="invalid-feedback">{ errors.email?.message }</div>   
                            </div>
                            <div className="form-group">
                                <Input elementID="password" label="Password" name="password" type="password" placeholder="********" disableAutocomplete="true" errors={ errors } register={ register } />
                                <div className="invalid-feedback">{ errors.password?.message }</div>
                            </div>
                        </div>
                        <Button isSubmitting={ isSubmitting } label="create_user_cta_label" />
                        { (() => {
                            if (errors.apiError) {
                                if (errors.apiError.message === "Bad Request") {
                                    return (
                                        <AlertError message="email_exists" />
                                    );
                                }
                                
                                return (
                                    <AlertError />
                                );
                            }
                        })() }
                    </form>
                </div>
            </main>
        </div>
    );
};

export { UserAdd };
