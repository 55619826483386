import UserAtom from "../atoms/userAtom";
import { useSetRecoilState } from "recoil";
import { HistoryHelper } from "../helpers/historyHelper";
import { fetchHelper } from "../helpers/fetchHelper";

const useUserActions = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const fetchHelperInstance = fetchHelper();
    const setUser = useSetRecoilState(UserAtom);

    const login = (email, password) => {
        return fetchHelperInstance.post(`${baseUrl}/auth`, { email, password })
            .then(user => {
                localStorage.setItem("user", JSON.stringify(user));
                setUser(user);

                const { from } = HistoryHelper.location.state || { from: { pathname: "/" } };
                HistoryHelper.push(from);
            });
    };

    const logout = () => {
        localStorage.removeItem("user");
        setUser(null);

        HistoryHelper.push("/login");
    };

    return {
        login,
        logout
    };
};

export { useUserActions };
