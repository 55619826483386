import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Modal = (props) => {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();

	return (
		<>
		<button type="button" onClick={ () => setShowModal(true) }>
			<props.button />
		</button>
		{ showModal ? (
			<>
			<div className="items-center flex fixed inset-0 z-50">
				<div className="mx-auto">
					<div className="border-0 rounded-lg relative bg-white max-w-xl text-center p-10">
						<button 
							className="border-0 text-gray-900 text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-3 top-2" 
							onClick={() => setShowModal(false)}
						>
							<span>×</span>
						</button>
						<h3 className="text-2xl font-semibold text-gray-900 mb-5 mt-0 text-wrap">{ props.title }</h3>
						<div className="text-gray-900 text-lg text-wrap">
							{ t(props.content) }
						</div>
						{ props.fetchButtonLabel && (
							<div className="rounded-b mt-10 mx-auto">
								<button 
									className="transition duration-500 ease-in-out bg-green-700 text-white p-4 w-full rounded-full font-bold font-display focus:outline-none focus:shadow-outline hover:bg-green-500 shadow max-w-sm uppercase"
									onClick={ () => props.fetchButton(props.fetchData) }
								>
									{ t(props.fetchButtonLabel) }
								</button>
							</div>
						) }
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
			</>
		) : null }
		</>
	);
};

export { Modal };
