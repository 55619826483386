import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavHref = (props) => {    
    const { t } = useTranslation();
    const location = useLocation();
    const isActive = location.pathname.indexOf(props.path) > -1;
    let className = "transition duration-500 ease-in-out inline-flex items-center py-3 rounded-lg px-2";

    if (isActive) {
        className = `${className} text-green-700 bg-white`;
    } else {
        className = `${className} text-white hover:bg-green-500 focus:bg-green-500`;
    }

    return (
        <NavLink 
            exact
            to={ props.path }
            className={ className }
        >
            { props.icon && (
                <props.icon className="h-5" />
            ) }

            <span className="ml-2" >{ t(props.label) }</span>
        </NavLink>
    );
};

export { NavHref };
