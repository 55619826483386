import UserAtom from "../atoms/userAtom";
import { useRecoilState } from "recoil";
import { HistoryHelper } from "./historyHelper";

const fetchHelper = () => {
    const [user, setUser] = useRecoilState(UserAtom);

    const request = (method) => {
        return (url, body) => {
            const requestOptions = {
                method,
                headers: authHeader(url)
            };

            if (body) {
                requestOptions.headers["Content-Type"] = "application/json";
                requestOptions.body = JSON.stringify(body);
            }

            return fetch(url, requestOptions).then(handleResponse);
        };
    };

    const authHeader = (url) => {
        const token = user?.accessToken;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);

        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    };
    
    const handleResponse = (response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            
            if (!response.ok) {
                if ([401, 403].includes(response.status) && user?.accessToken) {
                    localStorage.removeItem("user");
                    setUser(null);
                    HistoryHelper.push("/login");
                }
    
                const error = response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    };    

    return {
        get: request("GET"),
        post: request("POST"),
        patch: request("PATCH"),
        delete: request("DELETE")
    };
};

export { fetchHelper };
