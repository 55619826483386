import React from "react";
import { NavHref } from "./navHrefComponent";

const Button = (props) => {    
    return (
        <button>
            <NavHref { ...props } />
        </button>
    );
};

export { Button };
