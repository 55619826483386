import React, { useEffect, Fragment } from "react";
import config from "../../_config/config";
import { useTable, usePagination, useFilters } from "react-table";
import { useTranslation } from "react-i18next";

const TextFilter = ({ label, column: { filterValue, setFilter } }) => {
  return (
    <div className="form-group">
      <label className="text-sm font-bold text-green-700 tracking-wide">{ label }</label>
      <input
        type="email"
        className="w-full py-2 border-b border-gray-300 focus:outline-none focus:border-green-500"
        value={ filterValue || "" }
        onChange={ (e) => setFilter(e.target.value || undefined) }
        placeholder="es. mario.rossi@anadiag.it"
      />
    </div>
  );
};

const Table = ({ columns, data, fetchData }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: config.TABLE_PAGE_SIZE,
        hiddenColumns: ["id", "filename", "status", "operator_firstName", "operator_lastName", "notes"],
      },
    },
    useFilters,
    usePagination
  );

  const { t } = useTranslation();

  useEffect(() => {
    fetchData(pageSize, pageIndex);
  }, [pageIndex, pageSize]);

  return (
    <Fragment>
      <div className="mt-4 flex flex-col">
        <div className="mb-10">
          { headerGroups.map((headerGroup) => (
            <div key={ headerGroup } { ...headerGroup.getHeaderGroupProps() }>
              { headerGroup.headers.map((column) => (
                (column.id === "operator_email" || column.id === "email") && (
                  <div key={ column.id } { ...column.getHeaderProps() }>
                    <TextFilter label={ t("search_by_email") } column={ column } />
                  </div>
                )
              )) }
            </div>
          )) }
        </div>
        <div className="align-middle inline-block min-w-full shadow overflow-hidden">
          <table className="min-w-full table-auto" { ...getTableProps() }>
            <thead className="bg-green-700">
              { headerGroups.map((headerGroup, keyr) => (
                <tr key={ keyr } { ...headerGroup.getHeaderGroupProps() } scope="col">
                  { headerGroup.headers.map((column, keyc) => (
                    <th
                      key={ keyc }
                      className="px-4 py-3 text-white text-left text-xs leading-4 font-bold uppercase tracking-wider"
                      { ...column.getHeaderProps() }
                    >
                      { t(column.render("Header")) }
                    </th>
                  )) }
                </tr>
              )) }
            </thead>
            <tbody { ...getTableBodyProps() }>
              { (page.length > 0 &&
                page.map((row, keyr) => {
                  prepareRow(row);
                  return (
                    <tr key={ keyr } { ...row.getRowProps() } className="even:bg-gray-100">
                      { row.cells.map((cell, keyc) => {
                        return (
                          <td key={ keyc } className="px-4 py-4 whitespace-nowrap text-sm text-left" { ...cell.getCellProps() }>
                            { cell.render("Cell") }
                          </td>
                        );
                      }) }
                    </tr>
                  );
                })) || <span className="block py-4 px-6">{ t("noResultsFound") }</span> }
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export { Table };
