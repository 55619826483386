import config from "../_config/config";

const removeKeyFromObj = (obj, key) => {
    if (key instanceof Object) {
        key.forEach(k => delete obj[k]);
    } else {
        delete obj[key];
    }

    return obj;
};

const setupColumnsForReactTable = (row) => {
    let columns = [];

    Object.keys(row).map((value) => {
        let options = { "Header": value, "accessor": value };

        if (config.TO_FORMAT_DATE.includes(value)) {
            options.accessor = r => {
                return new Date(r[value]).toLocaleString(config.DEFAULT_LOCALE);
            };
            columns.push(options);
        } else {
            columns.push(options);
        }
    });

    return columns;
};

export { removeKeyFromObj, setupColumnsForReactTable };
