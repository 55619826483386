import React from "react";
import { useTranslation } from "react-i18next";

const UserTile = (props) => {    
    const { t } = useTranslation();

    return (
        <div className="inline-flex items-center p-3 bg-green-900">
            <span className="h-12 w-12 mr-2 rounded-full overflow-hidden">
                <img src={ `https://ui-avatars.com/api/?name=${encodeURIComponent(props.user.fullname).replaceAll("%20", "+")}` } alt={ props.user.fullname } className="h-full w-full object-cover" />
            </span>
            <div className="flex flex-col" >
                <span className="font-semibold text-white">{ props.user.fullname }</span>
                <span className="text-xs text-white">{ t("administrator") }</span>
            </div>
        </div>
    );
};

export { UserTile };
