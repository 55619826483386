import React, { useState } from "react";
import config from "../../_config/config";
import { Nav, HeaderTitle } from "../../components/layout/_index";
import { Table } from "../../components/table/_index";
import { Modal } from "../../components/modal/_index";
import { fetchHelper } from "../../helpers/fetchHelper";
import { removeKeyFromObj, setupColumnsForReactTable } from "../../helpers/objectHelper";
import { flatten } from "flat";
import { useTranslation } from "react-i18next";
import { DownloadIcon, TrashIcon, DocumentTextIcon } from "@heroicons/react/outline";

const columnsToRemove = ["_id", "operator__id", "operator_id", "fileStatus"];
const baseUrl = process.env.REACT_APP_API_URL;

const StorageFileList = () => {
    const [fileColumns, setFileColumns] = useState([]);
    const [fileList, setFileList] = useState([]);
    const { t } = useTranslation();
    const fetchHelperInstance = fetchHelper();

    const removeFile = (reading) => {  
        const payload = {
            "filename": reading.filename,
            "fileStatus": config.STATUS_FILE_TO_DELETE
        };

        fetchHelperInstance.patch(`${baseUrl}/readings/${reading.id}`, payload)
            .then(() => {
                getFileList(config.TABLE_PAGE_SIZE, 0);
            });
    };

    const getFileList = (perPage, page) => {
        return fetchHelperInstance.get(`${baseUrl}/readings?limit=${perPage}&page=${page}`)
            .then(fileList => {
                if (fileList.length > 0) {
                    let flattedFile;
                    let columns;

                    fileList.map((file, i) => {
                        flattedFile = flatten(file, { delimiter: "_" });
                        fileList[i] = removeKeyFromObj(flattedFile, columnsToRemove);
                    });

                    columns = setupColumnsForReactTable(fileList[0]);
                    columns.push({
                        Header: "",
                        accessor: "download",
                        Cell: (props) => {
                            const row = props.row.original;
                            const csvRemotePath = `${baseUrl}/csv/${row.filename}`;

                            return (
                                <div className="action download">
                                    <a href={ csvRemotePath } target="_blank" rel="noreferrer" download>
                                        <DownloadIcon />
                                    </a>
                                </div>
                            );
                        }
                    });

                    columns.push({
                        Header: "",
                        accessor: "delete",
                        Cell: (props) => {
                            const row = props.row.original;
                            const modalTitle = `${t("removeFileModalTitle")} "${row.name}"`;

                            return (
                                <Modal button={ TrashIcon } fetchData={ row } fetchButton={ removeFile } fetchButtonLabel="delete" title={ modalTitle } content="removeFileModalContent" />
                            );
                        }
                    });

                    columns.push({
                        Header: "",
                        accessor: "shownotes",
                        Cell: (props) => {
                            const row = props.row.original;

                            if (row.notes) {
                                const modalTitle = t("notes");

                                return (
                                    <Modal button={ DocumentTextIcon } fetchData={ row } title={ modalTitle } content={ row.notes } />
                                );
                            } else {
                                return null;
                            }
                        }
                    });

                    setFileColumns(columns);
                    setFileList(fileList);
                } else {
                    setFileColumns([]);
                    setFileList([]);
                }
            });
    };

    return (
        <div className="flex bg-white min-h-screen">
            <Nav />
            <main className="flex-grow p-10 pl-64 space-y-6 text-gray-900">
                <div className="flex flex-col space-y-6">
                    <HeaderTitle title="storage_page_title" />
                    <Table columns={ fileColumns } data={ fileList } fetchData={ getFileList } />
                </div>
            </main>
        </div>
    );
};

export { StorageFileList };
