import React, { useState } from "react";
import config from "../../_config/config";
import { Nav, HeaderTitle } from "../../components/layout/_index";
import { Table } from "../../components/table/_index";
import { fetchHelper } from "../../helpers/fetchHelper";
import { Modal } from "../../components/modal/_index";
import { removeKeyFromObj, setupColumnsForReactTable } from "../../helpers/objectHelper";
import { UserAddIcon, PencilIcon, PauseIcon, PlayIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

const UserList = () => {
    const [userColumns, setUserColumns] = useState([]);
    const [userList, setUserList] = useState([]);
    const fetchHelperInstance = fetchHelper();
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;
    
    const editUserStatus = (user) => {
        let newstatus = config.USER_STATUS_ACTIVE;

        if (user.status === newstatus) {
            newstatus = config.USER_STATUS_DEACTIVATED;
        }

        const payload = {
            "status": newstatus
        };

        fetchHelperInstance.patch(`${baseUrl}/users/${user.id}`, payload)
            .then(() => {
                getUserList(config.TABLE_PAGE_SIZE, 0);
            });
    };

    const getUserList = (perPage, page) => {
        return fetchHelperInstance.get(`${baseUrl}/users?limit=${perPage}&page=${page}`)
            .then(userList => {
                if (userList.length > 0) {
                    let columns;

                    userList.map((user) => {
                        removeKeyFromObj(user, ["_id", "permissionLevel"]);
                    });

                    columns = setupColumnsForReactTable(userList[0]);
                    columns.push({
                        Header: "",
                        accessor: "edit",
                        Cell: (props) => {
                            const row = props.row.original;
                            const editUrl = `/users/edit-user/${row.id}`;

                            return (
                                <div className="action edit">
                                    <a href={ editUrl }>
                                        <PencilIcon />
                                    </a>
                                </div>
                            );
                        }
                    });
                    columns.push({
                        Header: "",
                        accessor: "statuscontroller",
                        Cell: (props) => {
                            const row = props.row.original;
                            const icon = (row.status === config.USER_STATUS_ACTIVE) ? PauseIcon : PlayIcon;
                            const title = ((row.status === config.USER_STATUS_ACTIVE) ? t("deactive_user") : t("active_user")) + row.email;
                            const fetchButtonLabel = (row.status === config.USER_STATUS_ACTIVE) ? "deactive_user_cta" : "active_user_cta";

                            return (
                                <Modal button={ icon } fetchData={ row } fetchButton={ editUserStatus } fetchButtonLabel={ fetchButtonLabel } title={ title } />
                            );
                        }
                    });
                    
                    setUserColumns(columns);
                    setUserList(userList);
                }
            });
    };

    return (
        <div className="flex bg-white min-h-screen">
            <Nav />
            <main className="flex-grow p-10 pl-64 space-y-6 text-gray-900">
                <div className="flex flex-col space-y-6">
                    <HeaderTitle title="user_page_title" actionLabel="user_add_page_title" actionLink="/users/add-user" actionIcon={ UserAddIcon } />
                    <Table columns={ userColumns } data={ userList } fetchData={ getUserList } />
                </div>
            </main>
        </div>
    );
};

export { UserList };
