import React, { useEffect } from "react";
import * as Yup from "yup";
import userAtom from "../../atoms/userAtom";
import companyLogo from "../../_asset/logo.png";
import config from "../../_config/config";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useUserActions } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "../../components/form/_index";
import { AlertError } from "../../components/alert/_index";

const Login = ({ history }) => {
    const { t } = useTranslation();
    const user = useRecoilValue(userAtom);
    const userActions = useUserActions();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t("email_error_message")).required(t("email_error_message")),
        password: Yup.string().required(t("password_error_message"))
    });
                            
    const formOptions = {
        resolver: yupResolver(validationSchema) 
    };

    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (user) {
            history.push("/users");
        }
    }, []);

    const onSubmit = ({ email, password }) => {
        return userActions.login(email, password)
            .catch(error => {
                setError("apiError", { message: error });
            });
    };

    return (
        <div className="lg:flex h-full">
            <div className="lg:w-1/2 xl:max-w-screen-sm px-12 sm:px-24 md:px-48 lg:px-12 xl:px-24 h-auto">
                <img className="lg:max-w-xs m-auto my-20" src={ companyLogo } alt={ process.env.REACT_APP_DEFAULT_TITLE } />
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="form-group">
                        <Input elementID="usrEmail" label="Email" name="email" type="email" placeholder="email@gmail.com" disableAutocomplete="true" errors={ errors } register={ register } />
                        <div className="invalid-feedback">{ errors.email?.message }</div>   
                    </div>

                    <div className="form-group mt-10">
                        <Input elementID="password" label="Password" name="password" type="password" placeholder="********" disableAutocomplete="true" errors={ errors } register={ register } />
                        <div className="invalid-feedback">{ errors.password?.message }</div>
                    </div>
                    <Button isSubmitting={ isSubmitting } label="login_cta_label" />
                    { (() => {
                        if (errors.apiError) {
                            if (errors.apiError.message == config.HTTP_UNAUTHORIZED) {
                                return (
                                    <AlertError message="user_not_found" />
                                );
                            }
                            
                            return (
                                <AlertError />
                            );
                        }
                    })() }
                </form>
            </div>
            <div className="hidden lg:flex flex-1 bg-field-bg bg-cover bg-center"></div>
        </div>
    );
};

export { Login };
