import React from "react";
import { useTranslation } from "react-i18next";

const Button = (props) => {    
    const { t } = useTranslation();

    return (
        <button 
            disabled={ props.isSubmitting }
            className="transition duration-500 ease-in-out mx-auto block mt-20 bg-green-700 text-white p-4 w-full rounded-full font-bold font-display focus:outline-none focus:shadow-outline hover:bg-green-500 shadow max-w-xs"
        >
            { t(props.label) }
        </button>
    );
};

export { Button };
