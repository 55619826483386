const config = {
    "HTTP_UNAUTHORIZED": "Unauthorized",
    "TABLE_PAGE_SIZE": 100,
    "TO_FORMAT_DATE": ["updatedAt", "createdAt"],
    "DEFAULT_LOCALE": "it-IT",
    "STATUS_FILE_TO_DELETE": "deleted",
    "USER_STATUS_ACTIVE": "active",
    "USER_STATUS_DEACTIVATED": "deactivated",
    "OPERATOR_PERMISSION_LVL": 2
};

export default config;
