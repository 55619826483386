import React from "react";
import userAtom from "../../atoms/userAtom";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";

const PrivateComponent = ({ component: Component, ...rest }) => {
    const user = useRecoilValue(userAtom);

    return (
        <Route { ...rest } render={ props => {
            if (!user) {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
            }
            
            return <Component { ...props } />;
        } } />
    );
};

export { PrivateComponent };
