import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./buttonComponent";

const HeaderTitle = (props) => {    
    const { t } = useTranslation();

    return (
        <div className="flex flex-row justify-between">
            <h1 className="text-4xl font-semibold mb-2 text-green-900">{ t(props.title) }</h1>
            { props.actionLabel && props.actionLink ? (
                <Button label={ t(props.actionLabel) } path={ props.actionLink } icon={ props.actionIcon } />
            ) : null }
        </div>
    );
};

export { HeaderTitle };
