import React from "react";
import { useTranslation } from "react-i18next";

const AlertError = (props) => {    
    const { t } = useTranslation();

    return (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-10" role="alert">
            <p className="font-bold text-">{ t("error_title") }</p>
            <p>
                { props.message ? (
                    t(props.message)
                ) : (
                    t("error_message")
                ) }
            </p>
        </div>
    );
};

export { AlertError };
