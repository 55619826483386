import React, { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

const Input = (props) => {    
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    let disabledProps = {
        autoComplete: "off",
        role: "presentation"
    };

    return (
        <div className="relative">
            {props.label && (
                <label 
                    htmlFor={ props.elementID }
                    className="text-sm font-bold text-green-700 tracking-wide"
                >
                    { props.label }
                </label>
            )}

            <input
                id={ props.elementID }
                name={ props.name }
                type={ passwordShown ? "text" : props.type }
                className={ `w-full py-2 border-b border-gray-300 focus:outline-none focus:border-green-500 ${props.errors.email ? "is-invalid" : ""}` }
                placeholder={ props.placeholder }
                value={ props.value }
                { ...props.register(props.name) }
                { ...disabledProps } 
                onChange={ e => {
                    if (props.onchange) { 
                        props.onchange(e.target.value);
                    }
                } }
            />
            
            { props.type === "password" && (
                <i className="absolute w-5 right-0 bottom-3 cursor-pointer" onClick={ togglePasswordVisiblity }>{ passwordShown ? <EyeIcon /> : <EyeOffIcon /> }</i>
            ) }
        </div>
    );
};

export { Input };
