import React from "react";
import UserAtom from "../../atoms/userAtom";
import companyLogo from "../../_asset/logo-white.png";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useUserActions } from "../../actions/userActions";
import { NavHref } from "./_index";
import { UserTile } from "../user/_index";
import { UserGroupIcon, ArchiveIcon, LogoutIcon } from "@heroicons/react/outline";

const Nav = () => {
    const user = useRecoilValue(UserAtom);
    const userActions = useUserActions();

    if (!user) {
        return null;
    }
    
    return (
        <aside className="fixed left-0 top-0 h-screen flex flex-col nav-wrapper">
            <NavLink exact to="/" className="inline-flex items-center justify-center h-20 p-3 pt-4 w-full bg-green-900">
                <img className="h-full w-auto" src={ companyLogo } alt={ process.env.REACT_APP_DEFAULT_TITLE } />
            </NavLink>
            <UserTile user={user} />
            <div className="flex-grow flex flex-col justify-between text-white bg-green-700">
                <nav className="flex flex-col mx-4 my-6 space-y-4">
                    <NavHref label="users" path="/users" icon={ UserGroupIcon } />
                    <NavHref label="storage" path="/storage" icon={ ArchiveIcon } />
                </nav>
                <div className="flex justify-end cursor-pointer">
                    <a className="inline-flex p-3 justify-center items-center bg-green-900 transition duration-500 ease-in-out border-green-900 h-15 w-full border-t hover:bg-green-700 focus:bg-green-700 px-2" onClick={ userActions.logout }>
                        <LogoutIcon className="h-5 mr-2" /> Logout
                    </a>
                </div>
            </div>
        </aside>
    );
};

export { Nav };
